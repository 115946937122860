import loadable from "@loadable/component";
import { routePath } from "./layouts/SidebarLayout/Sidebar/SidebarMenu/items";
import SidebarLayout from "src/layouts/SidebarLayout";

const Dashboards = loadable(() => import("src/content/dashboards"));

const Roles = loadable(() => import("src/content/management-teams/roles"));
const Users = loadable(() => import("src/content/management-teams/teams"));
const Permission = loadable(() => import("src/content/management-teams/permission"));
const PermissionAccess = loadable(
  () => import("src/content/management-teams/permission/role-permission")
);

const ClientRoles = loadable(() => import("src/content/management-clients/roles"));
const ClientPermission = loadable(() => import("src/content/management-clients/permission"));
const ClientRolePermission = loadable(
  () => import("src/content/management-clients/permission/role-permission")
);
const Clients = loadable(() => import("src/content/management-clients/client"));

const listChildren = [
  {
    id: "allow",
    path: routePath.DASHBOARD.pathName,
    element: <Dashboards />,
  },

  // management module
  {
    id: routePath.ROLES.id,
    path: routePath.ROLES.pathName,
    element: <Roles />,
  },
  {
    id: routePath.PERMISSION.id,
    path: routePath.PERMISSION.pathName,
    element: <Permission />,
  },
  {
    id: routePath.ROLE_PERMISSION.id,
    path: routePath.ROLE_PERMISSION.pathName,
    element: <PermissionAccess />,
  },

  // management employees
  {
    id: routePath.EMPLOYEES.id,
    path: routePath.EMPLOYEES.pathName,
    element: <Users />,
  },

  // management Client
  {
    id: routePath.CLIENT.id,
    path: routePath.CLIENT.pathName,
    element: <Clients />,
  },
  {
    id: routePath.CLIENT_ROLES.id,
    path: routePath.CLIENT_ROLES.pathName,
    element: <ClientRoles />,
  },
  {
    id: routePath.CLIENT_PERMISSION.id,
    path: routePath.CLIENT_PERMISSION.pathName,
    element: <ClientPermission />,
  },
  {
    id: routePath.CLIENT_ROLE_PERMISSION.id,
    path: routePath.CLIENT_ROLE_PERMISSION.pathName,
    element: <ClientRolePermission />,
  },

  // management settings
  {
    id: "allow",
    path: routePath.CHANGE_PASSWORD.pathName,
    element: <></>,
  },
];

const routes = {
  path: "/",
  element: <SidebarLayout />,
  children: listChildren,
};

export default routes;
