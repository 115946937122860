import { MenuItem } from "./index";

export const routePath = {
  // AUTH
  SIGNIN: { id: "", pathName: "/signin" },

  //DASHBOARD
  DASHBOARD: { id: "allow", pathName: "/dashboard" },

  // MANAGEMENT MODULE
  ROLES: { id: "047cd028-dfb2-4d25-a6a2-34fa6a809a6a", pathName: "/management-teams/roles" },
  PERMISSION: {
    id: "14df527b-bd2c-4f4b-b957-4610f4143f41",
    pathName: "/management-teams/permission",
  },
  ROLE_PERMISSION: {
    id: "14df527b-bd2c-4f4b-b957-4610f4143f41",
    pathName: "/management-teams/permission/role-permission/:id",
  },

  // EMPLOYEES
  EMPLOYEES: { id: "cf555d97-44a9-48cb-9b99-6191461e1b50", pathName: "/employees" },

  // MANAGEMENT CLIENT
  CLIENT_ROLES: {
    id: "2257d532-7cf4-43b0-93d0-2a161a1c66f4",
    pathName: "/management-client/roles",
  },
  CLIENT_PERMISSION: {
    id: "8ca18312-5872-4a17-8136-49133c516646",
    pathName: "/management-client/permission",
  },
  CLIENT_ROLE_PERMISSION: {
    id: "8ca18312-5872-4a17-8136-49133c516646",
    pathName: "/management-client/permission/role-permission/:id",
  },
  CLIENT: { id: "e934056b-0491-4813-8066-3858bdf4995f", pathName: "/management-client/clients" },

  // CHANGE PASS
  CHANGE_PASSWORD: { id: "allow", pathName: "/settings/change-password" },

  // 404
  NOT_FOUND: { id: "", pathName: "/not-found" },
};

const menuItems: MenuItem[] = [
  {
    id: "allow",
    name: "Dashboard",
    link: routePath.DASHBOARD.pathName,
  },
  {
    id: "4684846b-ee96-4495-bf63-db9f9e1ce6a8",
    name: "Management Teams",
    link: "/management-teams",
    items: [
      {
        id: routePath.ROLES.id,
        name: "Roles",
        link: routePath.ROLES.pathName,
      },
      {
        id: routePath.PERMISSION.id,
        name: "Permission",
        link: routePath.PERMISSION.pathName,
      },
      {
        id: routePath.EMPLOYEES.id,
        name: "Teams",
        link: routePath.EMPLOYEES.pathName,
      },
    ],
  },
  {
    id: "25300aec-8677-4d8f-b01c-da30080ae396",
    name: "Management Client",
    link: "/management-client",
    items: [
      {
        id: routePath.CLIENT_ROLES.id,
        name: "Roles",
        link: routePath.CLIENT_ROLES.pathName,
      },
      {
        id: routePath.CLIENT_PERMISSION.id,
        name: "Permission",
        link: routePath.CLIENT_PERMISSION.pathName,
      },
      {
        id: routePath.CLIENT.id,
        name: "Clients",
        link: routePath.CLIENT.pathName,
      },
    ],
  },
  {
    id: "allow",
    name: "Settings",
    link: "/settings",
    items: [
      {
        id: routePath.CHANGE_PASSWORD.id,
        name: "Change Password",
        link: routePath.CHANGE_PASSWORD.pathName,
      },
    ],
  },
];

export default menuItems;
