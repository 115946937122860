import authReducer from "./auth"; // auth

// management modules
import rolesReducer from "./slices/roles";
import permissionReducer from "./slices/permission";
import usersReducer from "./slices/users";

// management client
import clientRolesReducer from "./slices/clients/roles";
import clientReducer from "./slices/clients/clients";
import clientPermissionReducer from "./slices/clients/permission";

// cities
import cities from "./slices/clients/cities";

export const reducer = {
  auth: authReducer,

  // management module
  roles: rolesReducer,
  permission: permissionReducer,
  users: usersReducer,

  // management client
  clientRoles: clientRolesReducer,
  client: clientReducer,
  clientPermission: clientPermissionReducer,

  // cities
  cities,
};
